export default {
  apiKey: "AIzaSyCstIa1jn5KipbCmXgUHOGx29TwGi_b4A4",
  authDomain: "la-note-bo.firebaseapp.com",
  databaseURL: "https://la-note-bo.firebaseio.com",
  projectId: "la-note-bo",
  storageBucket: "la-note-bo.appspot.com",
  messagingSenderId: "826277688063",
  appId: "1:826277688063:web:4fc5ae67e5eee9edf952ca",
  measurementId: "G-XFMQZTW7Q9"
};
